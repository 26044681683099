import React from 'react';

export function Home() {
    // return (
    //   <div>
    //     <h1>Ethan Yu</h1>
    //     <h2>Welcome!</h2>
    //     <p>Website is in progress, but feel free to look around!</p>
    //   </div>
    // )
    return (
      <div>
        <h1>TEST PAGE</h1>
        <h2>Test heading 1</h2>
        <p>Some content here</p>
        <p>More content here</p>
        <h2>Test heading 2</h2>
        <p>Some really really really really really really really long content here</p>
      </div>
    );
}

export default Home;